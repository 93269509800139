import React, { Component } from 'react';

class UnitSearch extends Component {
    render() {
        return (
            <div>
                <h1>Unit Search</h1>
            </div>
        );
    }
}

export default UnitSearch;